
import "./scss/style.scss";
import 'bootstrap/js/dist/index';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse';
//import 'bootstrap/js/dist/tab';
import 'lightslider';

import lozad from 'lozad';
//import functions from "./js/functions.js";
import "./js/globals.js";

window.observer = lozad('.lozad');
window.observer.observe();