const getFormData = $form => {
    const data = {};
    $.map($form.serializeArray(), o => (data[o.name] = o.value));
    return data;
};

const getUrlData = fromHash => {
    const data = {};
    let search = fromHash ? location.hash.replace('#', '') : location.search.replace('?', '');
    search.indexOf('&') > -1 && (search = search.split('&'));
    !Array.isArray(search) && (search = [search]);
    if ( search.length === 1 && search[0] === '' ) {
        return data;
    }
    $.map(search, param => data[decodeURIComponent(param.split('=')[0])] = decodeURIComponent(param.split('=')[1]));
    return data;
};

const setFormData = (form, data) => {
    $.map(data, (val, name) => {
        const elem = form[name];
        if ( !elem ) {
            return;
        }
        const $elem = $(elem);
        const date = $elem.closest('.datepicker')[0];
        if ( date ) {
            date._flatpickr.setDate(val);
        }
        else if ( elem.type === 'checkbox' ) {
            elem.checked = !!val;
        }
        else {
            $elem.val(val);
        }
        $elem.trigger('change');
    });
};

const setUrlData = (data, toHash) => {
    $.map(data, (value, key) => !value && (delete data[key]));
    if ( toHash ) {
        location.hash = $.param(data);
    } else {
        location.search = $.param(data);
    }
};

const getLang = () => {
    return (document.documentElement.lang || '').split('-')[0] || 'hr';
};

const $animateSelector = $('html, body');
const scrollToElement = ($elem, offset) => {
    $elem = $($elem);
    $elem.length && $animateSelector.animate({
        scrollTop: $elem.offset().top - (offset || 0)
    });
};

const smallWin = () => {
    return window.innerWidth < 992;
}

const formatMoney = (n, c, d, t) => {
    var c = isNaN(c = Math.abs(c)) ? 2 : c,
        d = d == undefined ? "." : d,
        t = t == undefined ? "," : t,
        s = n < 0 ? "-" : "",
        i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
        j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};

const setCookie = (key, value) => {
    let expires = new Date();
    expires.setTime(expires.getTime() + (365 * 24 * 60 * 60 * 1000));
    document.cookie = key + '=' + value + ';expires=' + expires.toUTCString() + '; path=/';
};

const getCookie = (key) => {
    let keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    return keyValue ? keyValue[2] : null;
};

module.exports = {
    getFormData: getFormData,
    getUrlData: getUrlData,
    setFormData: setFormData,
    setUrlData: setUrlData,
    getLang: getLang,
    scrollToElement: scrollToElement,
    smallWin: smallWin,
    formatMoney: formatMoney,
    setCookie: setCookie,
    getCookie: getCookie
};