import functions from "./functions.js";
$(function() {

    /*$('.navbar-toggler').on('click', e => {
        $('.navbar').css('height', 'auto');
        if($('.navbar-collapse .show')) {
            $('.navbar').css('height', '100vh');
        }
    });*/

    let lastScroll = 0;
    $(window).scroll(e => {
        if($(window).scrollTop() < lastScroll) {
            $('.top-menu').removeClass('position-absolute');
            $('.top-menu').addClass('fixed-top shadow bg-white');
        } else {
            $('.top-menu').addClass('position-absolute');
            $('.top-menu').removeClass('fixed-top shadow bg-white');
        }
        if($(window).scrollTop() < 1) {
            $('.top-menu').addClass('position-absolute');
            $('.top-menu').removeClass('fixed-top shadow bg-white');
        }
        lastScroll = $(window).scrollTop();
    });

    $('#navbarSupportedContent').on('shown.bs.collapse', function (e) {
        $('.navbar').addClass('opened');
    })
    $('#navbarSupportedContent').on('hidden.bs.collapse', function (e) {
        $('.navbar').removeClass('opened');
    })


    if ($('.mars-circular').length > 0) {
        $(window).scroll(function () {
            var wh = $(window).height() - 500;
            if ($(window).scrollTop() > $('.mars-circular').offset().top - wh) {
                $('.mars-dot').addClass('mars-dot-scroll');
                $('.mars-planet').addClass('mars-planet-scroll');
            }
        });
    }

    // get in touch
    // prevent click inside dropdown to close it
    $('.nav-item-contact .dropdown-menu').on('click', e => {
        if ( !e.target.classList.contains('la-times') ) {
            e.stopPropagation();
        } else {
            $('#navbarSupportedContent').collapse('hide');
            $('.btn-contact').dropdown('hide');
        }
    });
    // allow another btn to open it
    $('.btn-trigger-contact').on('click', e => {
        e.stopPropagation();
        $('#navbarSupportedContent').collapse('show');
        $('.btn-contact').dropdown('toggle');
    });
    // form submission
    $('form.form-contact').on('submit', e => {
        e.preventDefault();
        let $form = $(e.target);

        // trigger loading
        $form.find('.btn').prop('disabled', true)
             .find('.la-paper-plane').addClass('d-none')
             .end()
             .find('.la-spinner').removeClass('d-none');
        
        let params = {};
        $.each($form.serializeArray(), function (i, field) {
            params[field.name] = field.value;
            if ($form.hasClass('pg-contact')) {
                params['from_page'] = 'S kontakt stranice';
            } else if ($form.hasClass('pg-nav')) {
                params['from_page'] = 'Iz navbara';
            }
        });

        grecaptcha.ready(function () {
            grecaptcha.execute('6LdBsMIUAAAAAGXmaFq3Z5IGWpvGUoBKiT7OU9A9', { action: 'contact' }).then(function (token) {
                params['gRecaptchaToken'] = token;
                $.ajax({
                    url: '/contact',
                    method: 'POST',
                    data: params,
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                }).always(response => {
                    if (response.status == true) {
                        $form.parent().addClass('submitted');
                    }
                });
            });
        });



        // setTimeout(function () {
        //     $form.parent().addClass('submitted');
        // },2000);

    });
    
    // global tooltip init
    //('[data-toggle="tooltip"]').tooltip();

    // home slider init
    const $sliderMain = $("#slider-main");
    const $sliderMainItemsBg = $sliderMain.find('.slide-bg');
    const sliderAnim = (e, i) => {
        $sliderMainItemsBg.removeClass('active');
        $sliderMainItemsBg.eq(i || 0).addClass('active');
    };
    $sliderMain.lightSlider({
        item: 1,
        mode: 'fade',
        pager: false,
        slideMargin: 0,
        pauseOnHover: true,
        loop: true,
        auto: true,
        pause: 6500,
        speed: 500,
        enableDrag: false,
        prevHtml: '<i class="fa fa-angle-left"></i>',
        nextHtml: '<i class="fa fa-angle-right"></i>',
        onSliderLoad: sliderAnim,
        onAfterSlide: sliderAnim
    });

    $("#slider-social-proof").lightSlider({
        item: 3,
        //mode: 'fade',
        pager: true,
        controls: false,
        slideMargin: 40,
        pauseOnHover: true,
        loop: true,
        auto: true,
        pause: 4000,
        speed: 400,
        enableDrag: false,
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    item: 2,
                    slideMove: 1,
                    slideMargin: 6,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    item: 1,
                    slideMove: 1,
                    slideMargin: 25
                }
            }
        ]
    });
    $("#slider-testimonials").lightSlider({
        item: 1,
        //mode: 'fade',
        pager: true,
        controls: false,
        slideMargin: 0,
        pauseOnHover: true,
        loop: true,
        auto: false,
        pause: 7000,
        speed: 400,
        enableDrag: false
    });

    var referenceSlider = $('#referencesSlider').lightSlider({
        item: 1,
        auto: false,
        slideMargin: 0,
        pause: 10000,
        controls: false,
        pauseOnHover: true,
        loop: true,
        enableDrag: false,
    });

    $('#goPrevSlide').on('click', function() {
        referenceSlider.goToPrevSlide();
    });

    $('#goNextSlide').on('click', function() {
        referenceSlider.goToNextSlide();
    })

    // scrool to element handling
    $(document).on('click', '[data-scroll-to]', e => {
        //e.preventDefault();
        const $this = $(e.target);
        let scrollTo = $this.attr('data-scroll-to').split(',');
        functions.scrollToElement(scrollTo[0], scrollTo[1] || 0);
    });

    $('#cookie-bar-accept').on('click', function (e) {
        functions.setCookie('cookie_usage', 'accepted');
        $("#cookie-bar").addClass('d-none');
    });
   
});